































import { FeedbackDto } from '@portals/shared/admin/FeedbackDto';
import { format } from 'date-fns';
import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import { fetchFeedback } from '@/service/feedbackService';

interface Data {
  feedback?: FeedbackDto[];
}

export default Vue.extend({
  components: {
    Loader,
    DnbIcon,
  },
  data(): Data {
    return {
      feedback: undefined,
    };
  },
  async mounted() {
    const feedback = await fetchFeedback();
    this.feedback = feedback;
  },
  methods: {
    dateFormatter(date: string) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY HH:mm');
    },
    helpfulFormatter(helpful: boolean) {
      return helpful ? 'Helpful' : 'Not helpful';
    },
  },
});
